import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Image,
} from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TextContainer from "../components/text-container"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 4em 4em 0em 4em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  @media only screen and (min-width: 1200px) {
    margin: 0em 2em !important;
  }
`

const Header = styled(SUIHeader)`
  font-size: ${props =>
    (props.as === "h1" && "2.6em") ||
    (props.as === "h2" && "2.2em") ||
    (props.as === "h3" && "1.5em")};
`

const Button = styled.a`
  width: 100%;
  padding: 2em 0em !important;
  margin-top: 2em !important;
  text-transform: uppercase !important;
  background: #d7203d !important;
  color: white !important;
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Education Providers"
      keywords={[`host family english`, `hfe`]}
    />
    <Hero
      title="EDUCATION PROVIDERS"
      dimmed
      img="https://res.cloudinary.com/mystay-international/image/upload/c_crop,g_center,h_300,w_1400/v1555990755/Host%20Family%20English/iStock-698051774_preview-1-1024x682.jpg"
    />
    <Grid
      style={{
        marginLeft: "0em",
        marginRight: "0em",
      }}
    >
      <GridRow>
        <Grid.Column>
          <h2>Building Student Numbers and Improving Outcomes</h2>
          <p>
            For Education Providers HFE offers an official HFE Partnering
            Package so that we can help build student numbers and outcomes
            through our extensive global network of International Agents.
          </p>
          <p>
            Our Education Partners will receive assistance in better packaging
            with their homestay program and the free upgrading of the quality of
            their homestay host program through the HFE Host Mentor program.
          </p>
          <h3>Join a Select Group of Partners</h3>
          <p>
            Participating Education Providers will join a select global group of
            partners offering globally recognized and credible Homestay Host
            Family English package to complement their students study experience
            abroad.
          </p>
          <p>
            HFE Homestay Packages are ideal entry point accommodation for
            international students arriving for the first time in their study
            Country. Our HFE hosts are committed to helping the international
            student with orientation, settling into their new environment as
            well as mentoring their English progress on a daily basis.
          </p>
          <p>
            Our goal will also to be to assist Partner Education Providers with
            building their business both in student numbers and student outcomes
            as well as through offering more attractive commercial packaging
            options relating to homestay.
          </p>
        </Grid.Column>
      </GridRow>
      <Grid.Row centered>
        <Grid.Column mobile="8" tablet="5" largeScreen="3">
          <Button className={`ui button`} href="/contact-us/business-enquiries">
            Contact Us
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Layout>
)

export default IndexPage
